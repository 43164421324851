// https://github.com/rails/rails/issues/32208
import { DirectUpload } from "@rails/activestorage";

async function activeStorageUpload(file, host, self) {
  const upload = new DirectUpload(
    file,
    `${host}/rails/active_storage/direct_uploads`,
    self
  );

  return new Promise((resolve, reject) => {
    upload.create((err, blob) => {
      if (err) reject(err);
      else resolve(blob);
    });
  });
}

export default activeStorageUpload;
