<template>
  <div class="vehicle-form__publication">
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div class="col">
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.publication.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.publication.description") }}
        </RdxInfo>
      </div>
      <div class="col-xl-8 p-4">
        <div class="mb-4">
          <CiHeading level="2" class="mb-2">
            {{ $t("stock.shared-form.publication.table-header") }}
          </CiHeading>
          <RdxInput
            v-model="publication_lists_search"
            :pending="pending.load || pending.save"
            type="search"
            :placeholder="
              $t('stock.shared-form.publication.search-placeholder')
            "
            autocomplete="off"
            class="mb-0"
            data-cy="publication_search"
          >
            <RdxIcon slot="prefix" class="ri-search-line" />
          </RdxInput>
        </div>

        <CiPublicationTable
          :value="filteredListings"
          :selected-listings="selectedListings"
          class="new-vehicle-view__table"
          data-cy="listings"
          @change="onPublicationChange"
        />

        <RdxInfo icon="ri-information-line">
          {{ $t("stock.shared-form.publication.note") }}
        </RdxInfo>
      </div>
    </div>
  </div>
</template>

<script>
import CiPublicationTable from "@/components/molecules/CiPublicationTable.vue";
import {
  RdxIcon,
  RdxInfo,
  RdxInput,
  RdxDivider
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "VehicleFormPublication",

  components: {
    CiPublicationTable,
    RdxIcon,
    RdxInfo,
    RdxInput,
    RdxDivider,
    CiHeading
  },

  props: {
    pending: {
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    listingsOptions: {
      type: Array,
      default: () => []
    },
    initialListings: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      publication_lists_search: null,
      selectedListings: []
    };
  },

  computed: {
    filteredListings() {
      let queryString = this.publication_lists_search || "";
      queryString = queryString.toLowerCase();

      return this.listingsOptions.filter(({ name, owner }) => {
        name = name || "";
        owner = owner || "";

        return (
          name.toLowerCase().includes(queryString) ||
          owner.toLowerCase().includes(queryString)
        );
      });
    }
  },

  watch: {
    initialListings(value) {
      this.selectedListings = value.map(({ id }) => id);
    }
  },

  methods: {
    onPublicationChange(ids) {
      this.$emit("vehicle:listings", ids);
    }
  }
};
</script>
