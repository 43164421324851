<template>
  <div class="vehicle-form__attachment">
    <!-- attachment -->
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.attachment.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.attachment.description") }}
        </RdxInfo>
      </div>
      <div class="col-xl-8 p-4">
        <RdxFilePicker
          :pending="pending.load || pending.save"
          :accept="`application/pdf`"
          :label="$t('stock.shared-form.attachment.file-picker-label')"
          :custom-file-label="
            $t('stock.shared-form.attachment.select-file-button')
          "
          class="mb-2"
          data-cy="attachment"
          @change="uploadAttachment"
        />
        <div
          v-if="showInitialAttachment && !pending.save"
          class="rdx-file-picker__file"
        >
          <div>
            <RdxIcon class="ri-attachment-2 mr-1" />
            <span class="rdx-file-picker__file-name">
              {{ initialAttachmentUrl.split("/").slice(-1)[0] }}
            </span>
          </div>
          <RdxIcon
            class="ri-delete-bin-7-line rdx-file-picker__clear"
            @click="removeInitialAttachment"
          />
        </div>
        <RdxInfo>{{
          $t("stock.shared-form.attachment.file-picker-note")
        }}</RdxInfo>
      </div>
    </div>
  </div>
</template>

<script>
import ASUpload from "@/utils/active-storage-upload";

import {
  RdxIcon,
  RdxInfo,
  RdxFilePicker,
  RdxDivider
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "VehicleFormAttachment",

  components: {
    RdxIcon,
    RdxInfo,
    RdxFilePicker,
    RdxDivider,
    CiHeading
  },

  props: {
    pending: {
      required: true,
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    initialAttachmentUrl: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      initialAttachmentRemoved: false,
      vehicle: {
        attachment: null
      }
    };
  },

  computed: {
    showInitialAttachment() {
      return this.initialAttachmentUrl && !this.initialAttachmentRemoved;
    }
  },

  watch: {
    "vehicle.attachment"(value) {
      this.$emit("vehicle:attachment", value);
    }
  },

  methods: {
    uploadAttachment(file) {
      if (file) {
        this.$emit("attachment-loading");
        this.initialAttachmentRemoved = true;
        const URL = process.env["VUE_APP_API_URL"];
        const uploadedAttachmentPromises = [...file].map(file => {
          return ASUpload(file, URL).then(({ signed_id }) => {
            this.vehicle.attachment = signed_id;
          });
        });

        Promise.all(uploadedAttachmentPromises).then(() => {
          this.$toast.success(
            this.$t("stock.shared-form.attachment.upload-success")
          );
          this.$emit("attachment-loaded");
        });
      } else {
        this.vehicle.attachment = null;
        this.$toast.info(
          this.$t("stock.shared-form.attachment.delete-success")
        );
      }
    },

    removeInitialAttachment() {
      this.initialAttachmentRemoved = true;
      this.vehicle.attachment = null;
      this.$emit("vehicle:attachment", null);
      this.$toast.info(this.$t("stock.shared-form.attachment.delete-success"));
    }
  }
};
</script>

<style lang="scss">
.vehicle-form__attachment {
  .rdx-file-picker {
    &__file {
      cursor: default;
      &:hover {
        background: $grey-3;
      }
    }

    &__file-name {
      color: $grey-8;
    }
  }
}
</style>
