<template>
  <nav class="ci-vehicle-form-nav">
    <ul class="ci-vehicle-form-nav__list">
      <li
        v-for="(item, i) in anchorsList"
        :key="`item-${i}`"
        class="ci-vehicle-form-nav__list-item"
        :class="{
          'ci-vehicle-form-nav__list-item--active':
            item.className === activeAnchor
        }"
        @click="scrollTo(item.className)"
      >
        {{ item.label }}
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "CiVehicleFormNav",
  props: {
    activeAnchor: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      anchorsList: [
        {
          label: this.$t("stock.shared-form.details.header"),
          className: "vehicle-form__details"
        },
        {
          label: this.$t("stock.shared-form.availability.header"),
          className: "vehicle-form__availability"
        },
        {
          label: this.$t("stock.shared-form.media.header"),
          className: "vehicle-form__media"
        },
        {
          label: this.$t("stock.shared-form.specification.header"),
          className: "vehicle-form__specification"
        },
        {
          label: this.$t("stock.shared-form.price.header"),
          className: "vehicle-form__price"
        },
        {
          label: this.$t("stock.shared-form.equipment.header"),
          className: "vehicle-form__equipment"
        },
        {
          label: this.$t("stock.shared-form.attachment.header"),
          className: "vehicle-form__attachment"
        },
        {
          label: this.$t("stock.shared-form.publication.header"),
          className: "vehicle-form__publication"
        }
      ]
    };
  },

  methods: {
    scrollTo(className) {
      this.$scrollTo(`.${className}`, {
        container: ".new-vehicle-view__inner",
        duration: 800,
        onDone: this.scrollingDone
      });
    },

    scrollingDone() {
      this.$emit("scrolling-done");
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-form-nav {
  position: sticky;
  right: 0;
  z-index: 1;
  &__list {
    list-style: none;
    position: absolute;
    right: 0;
    padding: 16px 57px 16px 16px;
    margin: 58px 20px 0 0;
    background: $grey-1;
  }
  &__list-item {
    font-size: 12px;
    line-height: 20px;
    color: $grey-9;
    font-weight: 400;
    border-left: 1px solid $grey-4;
    padding-left: 16px;
    cursor: pointer;
    &--active {
      color: $blue-6;
      border-left: 1px solid $blue-6;
    }
  }
}
@media (max-width: 1200px) {
  .ci-vehicle-form-nav {
    display: none;
  }
}
</style>
