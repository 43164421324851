<template>
  <div class="vehicle-form__equipment">
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.equipment.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.equipment.description") }}
        </RdxInfo>
      </div>
      <div class="col-xl-8 p-4">
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.equipment.overview-label") }}
        </CiHeading>
        <RdxTextarea
          v-model="vehicle.long_description"
          :pending="pending.load || pending.save"
          :label="'TRAMER records and other notes'"
          data-cy="description"
        />
        <CiHeading level="2">
          Approved Vehicle Options
        </CiHeading>
        <RdxTextarea
          v-model="vehicle.approved_specs"
          :pending="pending.load || pending.save"
          :label="'Enter one item per line'"
          data-cy="approved_specs"
        />

        <CiHeading level="2">
          {{ $t("stock.shared-form.equipment.standard-features-header") }}
        </CiHeading>
        <CiSpecsTable
          ref="features-standard"
          v-model="vehicle.features_standard"
          class="ci-table--hover new-vehicle-view__table"
          :categories="featureCategoriesOptions"
        >
          <template #info>
            {{
              $t("stock.shared-form.equipment.standard-features-description")
            }}
          </template>
        </CiSpecsTable>

        <CiHeading level="2">
          {{ $t("stock.shared-form.equipment.optional-feateures-header") }}
        </CiHeading>
        <CiSpecsTable
          ref="features-optional"
          v-model="vehicle.features_optional"
          class="ci-table--hover new-vehicle-view__table"
          :categories="featureCategoriesOptions"
        >
          <template #info>
            {{
              $t("stock.shared-form.equipment.optional-feateures-description")
            }}
          </template>
        </CiSpecsTable>

        <RdxInfo icon="ri-information-line">
          {{ $t("stock.shared-form.equipment.tip") }}
        </RdxInfo>

        <!-- <rdx-info icon="ri-information-line">
          Note: You can paste multiple codes and descriptions at a time.
        </rdx-info> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  RdxInfo,
  RdxTextarea,
  RdxDivider
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";
import CiSpecsTable from "@/components/molecules/CiSpecsTable";

export default {
  name: "VehicleFormEquipmet",

  components: {
    RdxInfo,
    RdxTextarea,
    RdxDivider,
    CiHeading,
    CiSpecsTable
  },

  props: {
    pending: {
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    featureCategoriesOptions: {
      type: Array,
      default: () => []
    },
    initialVehicle: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      vehicle: {
        long_description: null,
        approved_specs: null,
        features_standard: [],
        features_optional: []
      },

      dataLoaded: false
    };
  },

  watch: {
    initialVehicle: {
      deep: true,
      async handler(value) {
        this.vehicle.long_description = value.long_description;
        this.vehicle.approved_specs = value.approved_specs;
        this.vehicle.features_standard = value.features_standard;
        this.vehicle.features_optional = value.features_optional;
        this.dataLoaded = await true;
      }
    },

    "vehicle.long_description"(description) {
      this.$emit("vehicle:long_description", description);
    },
    "vehicle.approved_specs"(approved_specs) {
      this.$emit("vehicle:approved_specs", approved_specs);
    },
    "vehicle.features_standard"(features) {
      if (this.dataLoaded) {
        this.$emit("vehicle:features_standard", features);
      }
    },

    "vehicle.features_optional"(features) {
      if (this.dataLoaded) {
        this.$emit("vehicle:features_optional", features);
      }
    }
  },

  mounted() {
    for (let i = 0; i < 3; i++) {
      this.$refs["features-standard"].addRow();
      this.$refs["features-optional"].addRow();
    }
    if (!Object.keys(this.initialVehicle).length) {
      this.dataLoaded = true;
    }
  }
};
</script>
