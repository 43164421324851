<!-- eslint-disable vue/no-mutating-props -->
<template>
  <tr class="ci-specs-table__row">
    <td>
      <RdxCheckbox
        v-model="checked"
        class="ci-specs-table__checkbox"
        @change="onSelectRow($event)"
      />
    </td>
    <td class="p-0">
      <RdxTextarea
        v-model="row.code"
        class="ci-specs-table__input"
        placeholder=""
        rows="1"
        @input="typingInCell(row.code, 'code')"
        @keydown.enter.prevent="handleEnterKeyPress($event, 'code')"
        @blur="hideSuggestions"
      />
      <ul v-if="suggestionsVisible('code')" :class="suggestionsRowClasses">
        <li
          v-for="(suggestion, j) in suggestions"
          :key="`suggestion-${j}`"
          class="ci-specs-table__suggestion"
          @mousedown="applySuggestion(suggestion)"
        >
          <span class="ci-specs-table__suggestion-code">
            {{ suggestion.attributes.code }}
          </span>
          <span>
            {{ suggestion.attributes.label }}
          </span>
        </li>
      </ul>
    </td>
    <td class="p-0">
      <ValidationObserver>
        <VRdxTextarea
          ref="description"
          v-model="row.label"
          placeholder=""
          class="ci-specs-table__input"
          rows="1"
          :rules="labelRequired"
          data-cy="ci-specs-table__input-label"
          :name="$t('stock.shared-form.equipment.spec-table.description')"
          @paste="handlePaste($event)"
          @input="typingInCell(row.label, 'label')"
          @keydown.enter.prevent="handleEnterKeyPress($event, 'label')"
          @blur="hideSuggestions"
        />
      </ValidationObserver>
      <ul
        v-if="suggestionsVisible('label')"
        class="ci-specs-table__suggestions-list"
      >
        <li
          v-for="(suggestion, j) in suggestions"
          :key="`suggestion-${j}`"
          class="ci-specs-table__suggestion"
          @mousedown="applySuggestion(suggestion)"
        >
          {{ suggestion.attributes.label }}
        </li>
      </ul>
    </td>
    <td class="p-0">
      <RdxSelect
        v-model="row.category"
        :searchable="false"
        :options="categories"
        class="m-0 ci-specs-table__select"
        :placeholder="
          $t(
            'stock.shared-form.equipment.spec-table.category-input-placeholder'
          )
        "
      >
        <template #singleLabel="{ option }">
          {{ $t(`global.equipment-options.${option}`) }}
        </template>
        <template #option="{ option }">
          {{ $t(`global.equipment-options.${option}`) }}
        </template>
      </RdxSelect>
    </td>
  </tr>
</template>

<script>
import {
  RdxCheckbox,
  RdxTextarea,
  RdxSelect
} from "@raffine/rdx-ui/lib/rdx-ui.umd";

import { ValidationObserver, withValidation } from "vee-validate";
const VRdxTextarea = withValidation(RdxTextarea, ({ errors }) => ({ errors }));

Array.prototype.removeItem = function(item) {
  return this.splice(this.indexOf(item), 1);
};

export default {
  name: "CiSpecsTableRow",

  components: {
    RdxCheckbox,
    RdxTextarea,
    RdxSelect,
    VRdxTextarea,
    ValidationObserver
  },

  props: {
    row: {
      type: Object,
      default: () => {}
    },
    categories: {
      type: Array,
      default: () => []
    },
    checked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      suggestions: [],
      suggestionsType: ""
    };
  },

  computed: {
    suggestionsRowClasses() {
      return [
        "ci-specs-table__suggestions-list",
        "ci-specs-table__suggestions-list--row"
      ];
    },

    labelRequired() {
      return this.row.code || this.row.category ? "required" : null;
    }
  },

  watch: {
    row: {
      deep: true,
      handler() {
        this.forceValidation();
      }
    }
  },

  methods: {
    onSelectRow(isChecked) {
      this.$emit("select-row", isChecked);
    },
    getSuggestions(query, category) {
      let minimalQueryLength = 3;
      if (category === "code") minimalQueryLength = 2;
      if (query && query.length >= minimalQueryLength) {
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.$http
            .get(`/suggestions/features/${category}/${query}`)
            .then(response => {
              this.suggestions = response.data.data;
            });
        }, 300);
      } else {
        this.hideSuggestions();
      }
    },

    typingInCell(query, category) {
      this.suggestionsType = category;
      this.getSuggestions(query, category);
    },

    handlePaste(event) {
      try {
        const pastedText = (
          event.clipboardData || window.clipboardData
        ).getData("text");
        if (!pastedText.length) return;
        const descriptions = pastedText.trim().split("\n");
        // if pasted text was multiline
        if (descriptions.length > 1) {
          event.preventDefault();
          this.$emit("batch-add-descriptions", descriptions);
        }
      } catch {
        this.$toast.error(this.$t("stock.paste-error"));
      }
    },

    handleEnterKeyPress(event, category) {
      event.target.blur();
      // notify parent component only if Enter key is pressed inside of a description field
      if (category === "label") {
        this.$emit("enter-key-press");
      }
    },

    // method used by the parent component CiSpecsTable
    // eslint-disable-next-line vue/no-unused-properties
    focusDescriptionField() {
      this.$refs.description.$el.querySelector("textarea").focus();
    },

    applySuggestion(suggestion) {
      this.$emit("apply-suggestion", suggestion);
      this.hideSuggestions();
    },
    hideSuggestions() {
      this.suggestions = [];
    },
    suggestionsVisible(category) {
      return (
        this.suggestions.length &&
        this.suggestionsType === category &&
        this.row[category]
      );
    },

    forceValidation() {
      if (this.$refs["specTableRowObserver"]) {
        this.$nextTick(() => {
          this.$refs["specTableRowObserver"].validate();
        });
      }
    }
  }
};
</script>
