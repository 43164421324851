<template>
  <div class="vehicle-form__specification">
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.specification.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.specification.description") }}
        </RdxInfo>
      </div>
      <div class="col-xl-8 p-4">

        <div class="row">
          <div class="col">
        <RdxButtonGroup
          :label="
            $t('stock.shared-form.specification.vehicle-status-input-label')
          "
          data-cy="state"
        >
          <RdxButton
            v-bind="isCurrent('state', 'new')"
            :disabled="pending.load || pending.save"
            data-cy="state_new"
            @click="setCurrent('state', 'new')"
          >
            {{ $t("global.states.new") }}
          </RdxButton>
          <RdxButton
            v-bind="isCurrent('state', 'used')"
            :disabled="pending.load || pending.save"
            data-cy="state_used"
            @click="setCurrent('state', 'used')"
          >
            {{ $t("global.states.used") }}
          </RdxButton>
          <RdxButton
            v-bind="isCurrent('state', 'approved')"
            :disabled="pending.load || pending.save"
            data-cy="state_approved"
            @click="setCurrent('state', 'approved')"
          >
            {{ $t("global.states.approved") }}
          </RdxButton>
        </RdxButtonGroup>
</div>
          <div class="col">

            <VRdxSelect
              v-model="vehicle.sale_state"
              :options="options.saleState"
              :pending="pending.load || pending.save"
              :label="
                'Sale state'
              "
              :name="
                'Sale state'
              "
              rules="required"
              data-cy="sale_state"
            />
          </div>
</div>
        <div class="row">
          <div class="col">
            <VRdxSelect
              v-model="vehicle.production_year"
              :options="years"
              :pending="pending.load || pending.save"
              :label="
                $t(
                  'stock.shared-form.specification.production-year-input-label'
                )
              "
              :name="
                $t(
                  'stock.shared-form.specification.production-year-input-label'
                )
              "
              rules="required"
              data-cy="production_year"
            />
          </div>
          <div class="col">
            <RdxInput
              v-model="vehicle.vin"
              :pending="pending.load || pending.save"
              :label="$t('stock.shared-form.specification.vin-input-label')"
              placeholder="000 00000 000000"
              data-cy="vin"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <RdxInput
              v-model="vehicle.equipment_no"
              :pending="pending.load || pending.save"
              label="Equipment no ex: 4030097"
              data-cy="equipment_no"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <VRdxInput
              v-model="vehicle.mileage"
              :pending="pending.load || pending.save"
              :label="$t('stock.shared-form.specification.mileage-input-label')"
              :name="$t('stock.shared-form.specification.mileage-input-label')"
              rules="required"
              :suffix="vehicle.mileage_unit || ''"
              data-cy="mileage"
            />
          </div>
          <div class="col">
            <VRdxSelect
              v-model="vehicle.transmission"
              :options="options.transmission"
              :pending="pending.load || pending.save"
              :label="
                $t('stock.shared-form.specification.transmission-input-label')
              "
              :placeholder="
                $t(
                  'stock.shared-form.specification.transmission-input-placeholder'
                )
              "
              :name="
                $t('stock.shared-form.specification.transmission-input-label')
              "
              rules="required"
              data-cy="transmission"
            >
              <template #singleLabel="{ option }">
                {{ $t(`global.transmissions.${option}`) }}
              </template>
              <template #option="{ option }">
                {{ $t(`global.transmissions.${option}`) }}
              </template>
            </VRdxSelect>
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <div class="mb-2">
              <label>
                {{ labelEngine }}
              </label>
            </div>
            <ValidationProvider
              ref="provider"
              v-slot="{ errors }"
              :name="labelEngine"
              rules="required"
              slim
            >
              <RdxCheckboxGroup
                v-model="vehicle.engine_type"
                :options="enginesOptions"
                :pending="pending.load || pending.save"
                data-cy="engine_type"
              />
              <RdxFieldError
                v-if="errors && errors.length"
                :errors="errors"
                class="mt-n3 mb-2"
              />
            </ValidationProvider>
          </div>

          <div class="col">
            <VRdxInput
              v-model="vehicle.power"
              :pending="pending.load || pending.save"
              :label="$t('stock.shared-form.specification.power-input-label')"
              :name="$t('stock.shared-form.specification.power-input-label')"
              placeholder="000"
              :suffix="vehicle.power_unit || ''"
              rules="required|numeric"
              data-cy="power"
            />

            <VRdxInput
              v-model="vehicle.engine_capacity"
              :pending="pending.load || pending.save"
              :label="
                $t(
                  'stock.shared-form.specification.engine-capacity-input-label'
                )
              "
              :name="
                $t(
                  'stock.shared-form.specification.engine-capacity-input-label'
                )
              "
              placeholder="0000"
              :suffix="$t(`global.engine_capacity_unit.cc`)"
              rules="required|numeric"
              data-cy="engine_capacity"
            >
            </VRdxInput>

            <template v-if="isElectric">
              <VRdxInput
                v-model="vehicle.battery_capacity"
                :pending="pending.load || pending.save"
                :label="
                  $t(
                    'stock.shared-form.specification.battery-capacity-input-label'
                  )
                "
                :name="
                  $t(
                    'stock.shared-form.specification.battery-capacity-input-label'
                  )
                "
                placeholder="00"
                suffix="kWh"
                rules="numeric"
                data-cy="battery_capacity"
              />
            </template>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <RdxInput
              v-model="vehicle.color_code_ext"
              :pending="pending.load || pending.save"
              label="Body Color Code ex: 1EH"
              data-cy="color_code_ext"
            />
          </div>
          <div class="col">
            <RdxInput
              v-model="vehicle.color_name_ext"
              :pending="pending.load || pending.save"
              label="Body Color Name ex: Varesine Blue"
              data-cy="color_name_ext"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <RdxInput
              v-model="vehicle.color_code_int"
              :pending="pending.load || pending.save"
              label="Interior Color Code ex: 304QD"
              data-cy="color_code_int"
            />
          </div>
          <div class="col">
            <RdxInput
              v-model="vehicle.color_name_int"
              :pending="pending.load || pending.save"
              label="Body Color Name ex: Raven (Ebony)"
              data-cy="color_name_int"
            />
          </div>
        </div>
        <div v-show="false" class="row">
          <div class="col-6">
            <VRdxSelect
              v-model="vehicle.color"
              :options="currentColorOptions"
              :pending="
                pending.load || pending.save || !ownerAndBrandAreSelected
              "
              vm-label="label"
              vm-track-by="label"
              :label="
                $t(
                  'stock.shared-form.specification.exterior-colour-input-label'
                )
              "
              :placeholder="
                $t(
                  'stock.shared-form.specification.exterior-colour-input-placeholder'
                )
              "
              :name="
                $t(
                  'stock.shared-form.specification.exterior-colour-input-label'
                )
              "
              class="mb-1"
              data-cy="exterior_color"
            />
            <RdxInfo
              v-if="vehicle.color && vehicle.color.canonical && !isCustomColor"
              data-cy="color-group"
            >
              {{
                $t("stock.shared-form.specification.colour-group-input-label")
              }}:
              <b>{{ $t(`global.colors.${vehicle.color.canonical}`) }}</b>
            </RdxInfo>
          </div>
          <div class="col-6">
            <VRdxInput
              v-model="vehicle.interior_color"
              :label="
                $t(
                  'stock.shared-form.specification.interior-colour-input-label'
                )
              "
              data-cy="interior_color"
            />
          </div>
        </div>

        <!-- <rdx-info v-if="!isCustomColor">
          Choose “Custom colour...” to add colour manually.
        </rdx-info> -->

        <template v-if="isCustomColor">
          <div class="row mt-3">
            <div class="col col-xl-6">
              <RdxInput
                v-model="customColor.paintName"
                type="text"
                :label="
                  $t('stock.shared-form.specification.paint-name-input-label')
                "
                data-cy="color-paint-name"
                @blur="createColor"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-xl-6">
              <RdxSelect
                v-model="customColor.colorGroup"
                :options="options.colorGroups"
                :pending="pending.load || pending.save"
                vm-label="label"
                vm-track-by="label"
                :label="
                  $t('stock.shared-form.specification.colour-group-input-label')
                "
                :placeholder="
                  $t(
                    // eslint-disable-next-line
                    'stock.shared-form.specification.colour-group-input-placeholder'
                  )
                "
                data-cy="color-group"
                @input="createColor"
              >
                <template #singleLabel="{ option }">
                  {{ $t(`global.colors.${option.value}`) }}
                </template>
                <template #option="{ option }">
                  {{ $t(`global.colors.${option.value}`) }}
                </template>
              </RdxSelect>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { serialise } from "kitsu-core";
import { ValidationProvider } from "vee-validate";
import { withValidation } from "vee-validate";
const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));
const VRdxSelect = withValidation(RdxSelect, ({ errors }) => ({ errors }));

import {
  RdxInfo,
  RdxInput,
  RdxButton,
  RdxButtonGroup,
  RdxCheckboxGroup,
  RdxSelect,
  RdxDivider,
  RdxFieldError
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import YEARS from "@/resources/years";

export default {
  name: "VehicleFormSpecification",

  components: {
    ValidationProvider,
    RdxInfo,
    RdxInput,
    RdxButton,
    RdxButtonGroup,
    RdxCheckboxGroup,
    RdxSelect,
    RdxDivider,
    CiHeading,
    RdxFieldError,

    VRdxInput,
    VRdxSelect
  },

  props: {
    pending: {
      required: true,
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    options: {
      type: Object,
      default: () => {}
    },
    colorOptionsList: {
      type: Array,
      default: () => []
    },
    selectedOwner: {
      type: Object,
      default: undefined
    },
    selectedBrand: {
      type: Object,
      default: undefined
    },
    initialVehicle: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      customColor: {
        paintName: null,
        colorGroup: null
      },
      vehicle: {
        state: "new",
        production_year: null,
        vin: null,
        equipment_no: null,
        color_code_ext: null,
        color_name_ext: null,
        color_code_int: null,
        color_name_int: null,
        mileage: null,
        mileage_unit: null,
        transmission: null,
        sale_state: null,
        engine_type: [],
        power: null,
        power_unit: null,
        engine_capacity: null,
        battery_capacity: null,
        color: null,
        interior_color: null
      },

      years: YEARS
    };
  },

  computed: {
    currentColorOptions() {
      let colors = this.colorOptionsList.filter(color => {
        return (
          parseInt(color.ownerId) === parseInt(this.selectedOwner?.id) ||
          parseInt(color.brandId) === parseInt(this.selectedBrand?.id)
        );
      });

      colors.push({
        label: this.$t("stock.shared-form.specification.custom-colour-option"),
        value: "custom-color"
      });

      return colors;
    },

    isCustomColor() {
      const { vehicle } = this;
      return !!(vehicle.color && vehicle.color.value === "custom-color");
    },
    isElectric() {
      if (this.vehicle.engine_type) {
        return !!(
          this.vehicle.engine_type.includes("bev") ||
          this.vehicle.engine_type.includes("mhev") ||
          this.vehicle.engine_type.includes("phev")
        );
      }
      return false;
    },

    enginesOptions() {
      return this.options.engines.map(el => {
        return {
          value: el.value,
          label: this.$t(`global.engines.${el.value}`)
        };
      });
    },

    labelEngine() {
      return this.$t("stock.shared-form.specification.engine-input-label");
    },
    ownerAndBrandAreSelected() {
      return Boolean(this.selectedOwner && this.selectedBrand);
    }
  },

  watch: {
    initialVehicle: {
      deep: true,
      handler(value) {
        this.vehicle.state = value.state;
        this.vehicle.production_year = value.production_year;
        this.vehicle.vin = value.vin;
        this.vehicle.color_code_ext = value.color_code_ext;
        this.vehicle.color_name_ext = value.color_name_ext;
        this.vehicle.color_code_int = value.color_code_int;
        this.vehicle.color_name_int = value.color_name_int;
        this.vehicle.equipment_no = value.equipment_no;
        this.vehicle.mileage = value.mileage;
        this.vehicle.mileage_unit = value.mileage_unit;
        this.vehicle.transmission = value.transmission;
        this.vehicle.sale_state = value.sale_state;
        this.vehicle.engine_type = value.engine_type;
        this.vehicle.power = value.power;
        this.vehicle.power_unit = value.power_unit;
        this.vehicle.engine_capacity = value.engine_capacity;
        this.vehicle.battery_capacity = value.battery_capacity;
        this.vehicle.color = value.color;
        this.vehicle.interior_color = value.interior_color;
      }
    },

    isElectric(value) {
      if (!value) {
        this.vehicle.battery_capacity = null;
        this.$emit("vehicle:battery_capacity", null);
      }
    },

    "vehicle.state"(state) {
      this.$emit("vehicle:state", state);
    },
    "vehicle.production_year"(year) {
      this.$emit("vehicle:production_year", year);
    },
    "vehicle.vin"(vin) {
      this.$emit("vehicle:vin", vin);
    },
    "vehicle.color_code_ext"(value) {
      this.$emit("vehicle:color_code_ext", value);
    },
    "vehicle.color_name_ext"(value) {
      this.$emit("vehicle:color_name_ext", value);
    },
    "vehicle.color_code_int"(value) {
      this.$emit("vehicle:color_code_int", value);
    },
    "vehicle.color_name_int"(value) {
      this.$emit("vehicle:color_name_int", value);
    },
    "vehicle.equipment_no"(equipment_no) {
      this.$emit("vehicle:equipment_no", equipment_no);
    },
    "vehicle.mileage"(mileage) {
      this.$emit("vehicle:mileage", mileage);
    },
    "vehicle.transmission"(transmission) {
      this.$emit("vehicle:transmission", transmission);
    },
    "vehicle.sale_state"(saleState) {
      this.$emit("vehicle:sale_state", saleState);
    },
    "vehicle.engine_type"(type) {
      this.$emit("vehicle:engine_type", type);
    },
    "vehicle.power"(power) {
      this.$emit("vehicle:power", power);
    },
    "vehicle.engine_capacity"(capacity) {
      this.$emit("vehicle:engine_capacity", capacity);
    },
    "vehicle.battery_capacity"(capacity) {
      this.$emit("vehicle:battery_capacity", capacity);
    },
    "vehicle.color"(color) {
      this.$emit("vehicle:color", color);
    },
    "vehicle.interior_color"(color) {
      this.$emit("vehicle:interior_color", color);
    },
    ownerAndBrandAreSelected(state) {
      if (!state) this.$set(this.vehicle, "color", null);
    }
  },

  methods: {
    createColor() {
      const { paintName, colorGroup } = this.customColor;
      if (paintName && colorGroup) {
        const COLOR = serialise("color", {
          name: paintName,
          canonical: colorGroup.value
        });

        this.$http
          .post("colors", COLOR)
          .then(({ data }) => {
            this.$emit("vehicle:color", {
              id: data.data.id,
              name: paintName,
              label: paintName,
              canonical: colorGroup.value
            });
          })
          .catch(e => new Error(e));
      }
    },

    isCurrent(key, state) {
      return {
        filled: !!(this.vehicle[key] === state),
        outline: !!(this.vehicle[key] !== state)
      };
    },

    setCurrent(key, state) {
      this.vehicle[key] = state;
    }
  }
};
</script>
