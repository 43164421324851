<template>
  <section class="ci-specs-table">
    <header class="ci-specs-table__top-toolbar">
      <RdxInfo>
        <slot name="info" />
      </RdxInfo>
      <nav class="ci-specs-table__toolbar-buttons">
        <RdxButton
          v-tooltip="
            $t('stock.shared-form.equipment.spec-table.delete-row-tooltip')
          "
          rect
          :disabled="deleteButtonDisabled"
          class="ci-specs-table__toolbar-button"
          @click="removeRows"
        >
          <RdxIcon class="ri-delete-bin-7-line rdx-icon--18" />
        </RdxButton>
      </nav>
    </header>

    <table class="ci-specs-table__table">
      <tr class="ci-specs-table__header">
        <td>
          <RdxCheckbox
            v-model="selectedAll"
            class="ci-specs-table__checkbox"
            :disabled="disableSelectAll"
            @change="toggleAll"
          />
        </td>
        <td class="ci-specs-table--code">
          <div class="ci-specs-table__cell--vertically-aligned">
            <span>
              {{ $t("stock.shared-form.equipment.spec-table.code") }}
            </span>
            <RdxIcon
              v-tooltip="
                $t('stock.shared-form.equipment.spec-table.code-tooltip')
              "
              class="ri-question-line rdx-icon--16 rdx-icon--bluish"
            />
          </div>
        </td>
        <td>{{ $t("stock.shared-form.equipment.spec-table.description") }}</td>
        <td class="ci-specs-table--category">
          {{ $t("stock.shared-form.equipment.spec-table.category") }}
        </td>
      </tr>
      <CiSpecsTableRow
        v-for="(row, i) in rows"
        :ref="`row-${i}`"
        :key="`row-${i}`"
        class="ci-specs-table__row"
        :row="row"
        :categories="categories"
        :checked="selectedRowsIndexes.includes(i)"
        @batch-add-descriptions="batchAddDescriptions($event, i)"
        @enter-key-press="focusCellBelow(i)"
        @apply-suggestion="applySuggestion($event, i)"
        @select-row="onSelectRow($event, i)"
      />
    </table>

    <div class="ci-specs-table__bottom-toolbar">
      <RdxButton
        v-tooltip="$t('stock.shared-form.equipment.spec-table.add-row-tooltip')"
        rect
        class="ci-specs-table__toolbar-button"
        @click="addRow"
      >
        <RdxIcon class="ri-add-line rdx-icon--18" />
      </RdxButton>
    </div>
  </section>
</template>

<script>
import {
  RdxIcon,
  RdxButton,
  RdxInfo,
  RdxCheckbox
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiSpecsTableRow from "@/components/molecules/CiSpecsTableRow";

Array.prototype.removeItem = function(item) {
  return this.splice(this.indexOf(item), 1);
};

export default {
  name: "CiSpecsTable",

  components: {
    RdxIcon,
    RdxButton,
    RdxInfo,
    RdxCheckbox,
    CiSpecsTableRow
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      rows: [],
      selectedAll: false,
      selectedRowsIndexes: [],
      checked: []
    };
  },

  computed: {
    deleteButtonDisabled() {
      return !this.selectedRowsIndexes.length;
    },
    disableSelectAll() {
      return !!(this.rows.length < 2);
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.rows = value;
      }
    },

    rows() {
      if (!this.rows.length) [...Array(3)].forEach(() => this.addRow());
      this.$emit("input", this.rows);
    }
  },

  methods: {
    addRow() {
      this.rows.push({
        code: null,
        label: null,
        category: null
      });
    },

    removeRows() {
      let forDeletion = this.selectedRowsIndexes.map(item => this.rows[item]);
      this.rows = this.rows.filter(item => !forDeletion.includes(item));
      this.selectedRowsIndexes = [];
      this.checked = [];
      this.selectedAll = false;
    },

    toggleAll() {
      this.selectedRowsIndexes = [];
      if (this.selectedAll) {
        this.selectedRowsIndexes = [...Array(this.rows.length).keys()];
      }
    },

    onSelectRow(isChecked, index) {
      if (isChecked) {
        if (!this.selectedRowsIndexes.includes(index)) {
          this.selectedRowsIndexes.push(index);
        }
      } else {
        if (this.selectedRowsIndexes.includes(index)) {
          this.selectedRowsIndexes.removeItem(index);
        }
      }
    },

    applySuggestion(suggestion, i) {
      this.rows[i].code = suggestion.attributes.code || null;
      this.rows[i].label = suggestion.attributes.label;
      this.rows[i].category = suggestion.attributes.category || null;
    },

    // rowIdx: start adding new rows with filled-in descriptions starting from rowIdx
    batchAddDescriptions(descriptions, rowIdx) {
      const newRows = descriptions.map(d => ({
        code: null,
        label: d,
        category: null
      }));
      this.rows.splice(rowIdx, 0, ...newRows);
      const nextRowIdx = rowIdx + descriptions.length;
      this.$nextTick(() => {
        const nextRowAfterPastedBatch = this.$refs[`row-${nextRowIdx}`][0];
        nextRowAfterPastedBatch.focusDescriptionField();
      });
      this.$toast.success(this.$t("stock.paste-successful"));
    },

    focusCellBelow(rowIdx) {
      // if rowIdx is the last row, add a new row below
      if (rowIdx === this.rows.length - 1) {
        this.addRow();
      }

      this.$nextTick(() => {
        const nextRow = this.$refs[`row-${rowIdx + 1}`][0];
        nextRow.focusDescriptionField();
      });
    }
  }
};
</script>

<style lang="scss">
.ci-specs-table {
  &__top-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__bottom-toolbar {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }

  &__toolbar-buttons {
    flex-shrink: 0;
  }

  &__toolbar-button {
    &:disabled {
      background: none;
      border: none;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    color: $grey-9;
    td {
      border: 1px solid $grey-4;
      padding: 8px 10px;
      vertical-align: top;
      position: relative;

      &:first-child {
        width: 38px;
      }
    }

    tr td:last-child {
      text-transform: capitalize;
    }
  }

  &__header {
    background: $grey-2;
    font-weight: 600;
    td {
      padding: 8px 7px;
      &:first-child {
        padding: 8px 10px;
      }
    }
  }

  &__checkbox {
    margin-bottom: 0;
  }

  &__input {
    position: relative;
    .rdx-field-error {
      padding: 0 5px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__input,
  &__textarea,
  &__select {
    margin: 0;

    * {
      border: 0 !important;
      border-radius: 0 !important;
    }
  }

  &__select {
    cursor: pointer;
  }

  &__cell {
    &--vertically-aligned {
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
      }
    }
  }

  &--code {
    width: 105px;
  }

  &--category {
    width: 140px;
  }

  &__suggestions-list {
    position: absolute;
    list-style: none;
    background: $grey-1;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }
  &__suggestions-list--row {
    width: 400%;
  }
  &__suggestion {
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    &:hover {
      background: $grey-3;
    }
  }
  &__suggestion-code {
    margin-right: 1em;
  }
}
</style>
