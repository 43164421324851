<template>
  <table class="ci-pb-table">
    <tr class="ci-pb-table__header">
      <td />
      <td>{{ $t("stock.shared-form.publication.name-cell") }}</td>
      <td>{{ $t("stock.shared-form.availability.owner-input-label") }}</td>
    </tr>
    <tr v-for="(row, i) in value" :key="`row-${i}`" class="ci-pb-table__row">
      <td>
        <RdxCheckbox
          :value="selectedListings.includes(row.id)"
          class="ci-pb-table__checkbox"
          @change="onChange($event, row.id)"
        />
      </td>
      <td>{{ row.name }}</td>
      <td>{{ row.owner }}</td>
    </tr>
  </table>
</template>

<script>
import { RdxCheckbox } from "@raffine/rdx-ui/lib/rdx-ui.umd";

Array.prototype.removeItem = function(item) {
  return this.splice(this.indexOf(item), 1);
};

export default {
  name: "CiPublicationTable",

  components: {
    RdxCheckbox
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    selectedListings: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      listingsIds: []
    };
  },

  watch: {
    selectedListings: {
      immediate: true,
      handler(value) {
        this.listingsIds = value;
      }
    }
  },

  methods: {
    onChange(isChecked, id) {
      let { listingsIds } = this;
      if (isChecked) {
        if (!listingsIds.includes(id)) {
          listingsIds.push(id);
        }
      } else {
        if (listingsIds.includes(id)) {
          listingsIds.removeItem(id);
        }
      }
      this.$emit("change", listingsIds);
    }
  }
};
</script>

<style lang="scss">
.ci-pb-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  color: $grey-9;

  td {
    border: 1px solid $grey-4;
    padding: 8px 10px;
    &:first-child {
      width: 38px;
    }
  }

  thead,
  &__header {
    background: $grey-2;
    font-weight: 600;
    td {
      padding: 8px 7px;
      &:first-child {
        padding: 8px 10px;
      }
    }
  }

  &__checkbox {
    margin-bottom: 0;
  }
}
</style>
