<template>
  <div class="vehicle-form__availability">
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.availability.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.availability.description") }}
        </RdxInfo>
      </div>
      <div class="col-xl-8 p-4">
        <div class="row">
          <div class="col">
            <VRdxSelect
              v-model="vehicle.status"
              :options="statusOptionsList"
              :pending="pending.load || pending.save"
              vm-label="label"
              vm-track-by="label"
              :label="$t('stock.shared-form.availability.status-input-label')"
              :placeholder="$t('stock.shared-form.availability.choose')"
              :name="$t('stock.shared-form.availability.owner-input-label')"
              rules="required"
              data-cy="status"
            />
          </div>
          <div class="col">
            <VRdxInput
              v-model="vehicle.delivery_date"
              :pending="pending.load || pending.save"
              type="date"
              :label="
                $t('stock.shared-form.availability.availability-date-label')
              "
              :name="
                $t('stock.shared-form.availability.availability-date-label')
              "
              data-cy="delivery_date"
            >
            </VRdxInput>
            <RdxInfo>{{
              $t("stock.shared-form.availability.availability-date-description")
            }}</RdxInfo>
            <RdxIcon
              v-if="vehicle.delivery_date"
              :class="clearButtonClasses"
              @click="resetDeliveryDate"
            />
          </div>
        </div>
        <div>

          <VRdxInput
            v-model="vehicle.branch_code"
            :label="'Bayi kodu (100001)'"
            data-cy="branch_code"
          />
        </div>

        <VRdxSelect
          v-model="vehicle.owner"
          :options="ownerOptionsList"
          :pending="pending.load || pending.save"
          vm-label="label"
          vm-track-by="label"
          :label="$t('stock.shared-form.availability.owner-input-label')"
          :placeholder="
            $t('stock.shared-form.availability.owner-input-placeholder')
          "
          :name="$t('stock.shared-form.availability.owner-input-label')"
          rules="required"
          data-cy="owner"
        />
      </div>
    </div>
    <CiWarningModal
      v-if="hidePriceModalOpened"
      class="vehicle-form__availability__modal"
      icon="ri-question-line"
      :heading="$t('stock.shared-form.availability.modal-heading')"
      :paragraph="$t('stock.shared-form.availability.modal-paragraph')"
      :cancel-label="$t('stock.shared-form.availability.modal-cancel')"
      :confirm-label="$t('stock.shared-form.availability.modal-confirm')"
      data-cy="availability-modal"
      @cancel="closeHidePriceModal"
      @confirm="hidePrice"
    />
  </div>
</template>

<script>
import {
  RdxInfo,
  RdxInput,
  RdxSelect,
  RdxDivider,
  RdxIcon
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";
import CiWarningModal from "@/components/CiWarningModal/CiWarningModal";

import { withValidation } from "vee-validate";
const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));
const VRdxSelect = withValidation(RdxSelect, ({ errors }) => ({ errors }));

export default {
  name: "VehicleFormAvailability",

  components: {
    RdxInfo,
    RdxDivider,
    CiHeading,
    RdxIcon,
    CiWarningModal,

    VRdxSelect,
    VRdxInput
  },

  props: {
    pending: {
      required: true,
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    ownerOptionsList: {
      required: true,
      type: Array,
      default: () => []
    },

    initialStatus: {
      type: String,
      validator: function(value) {
        return (
          [
            "stock",
            "reserved",
            "in_production",
            "delivery",
            "available_soon",
            "sold"
          ].indexOf(value) !== -1
        );
      },

      default: undefined
    },

    initialDeliveryDate: {
      type: String,
      default: ""
    },
    initialBranchCode: {
      type: String,
      default: ""
    },
    initialOwner: {
      type: Object,
      default: () => {}
    },
    priceHidden: {
      type: Boolean
    }
  },

  data() {
    return {
      vehicle: {
        status: {},
        delivery_date: null,
        branch_code: null,
        owner: null
      },

      hidePriceModalOpened: false
    };
  },

  computed: {
    statusOptionsList() {
      return [
        {
          label: this.$t("global.availabilities.stock"),
          value: "stock"
        },
        {
          label: this.$t("global.availabilities.reserved"),
          value: "reserved"
        },
        {
          label: this.$t("global.availabilities.in_production"),
          value: "in_production"
        },
        {
          label: this.$t("global.availabilities.delivery"),
          value: "delivery"
        },
        {
          label: this.$t("global.availabilities.available_soon"),
          value: "available_soon"
        },
        {
          label: this.$t("global.availabilities.sold"),
          value: "sold"
        }
      ];
    },

    clearButtonClasses() {
      return "vehicle-form__date-input-clear-button ri-close-circle-fill rdx-icon--18";
    }
  },

  watch: {
    initialStatus() {
      this.setStatusFromProp();
    },

    initialDeliveryDate(value) {
      this.vehicle.delivery_date = value;
    },
    initialBranchCode(value) {
      this.vehicle.branch_code = value;
    },
    initialOwner(value) {
      this.vehicle.owner = value;
    },
    "vehicle.status"(newStatus) {
      if (!newStatus) {
        this.vehicle.delivery_date = null;
        this.resetDeliveryDate();
      }
      if (
        !this.pending.load &&
        newStatus?.value === "sold" &&
        !this.priceHidden
      ) {
        this.hidePriceModalOpened = true;
      }
      this.$emit("vehicle:status", newStatus?.value);
    },

    "vehicle.delivery_date"(date) {
      this.$emit("vehicle:delivery_date", date);
    },
    "vehicle.branch_code"(val) {
      this.$emit("vehicle:branch_code", val);
    },
    "vehicle.owner"(owner) {
      this.$emit("vehicle:owner", owner);
    }
  },

  created() {
    this.setStatusFromProp();
  },

  methods: {
    setStatusFromProp() {
      this.vehicle.status = this.statusOptionsList.find(
        item => item.value === this.initialStatus
      );
    },

    closeHidePriceModal() {
      this.hidePriceModalOpened = false;
    },

    hidePrice() {
      this.$emit("hide-price");
    },
    resetDeliveryDate() {
      this.$emit("vehicle:delivery_date", null);
    }
  }
};
</script>

<style lang="scss">
.vehicle-form__date-input-clear-button {
  position: absolute;
  top: 27px;
  right: 55px;
  color: $grey-6;
  cursor: pointer;
  &:hover {
    color: $grey-7;
  }
}

@-moz-document url-prefix() {
  .vehicle-form__date-input-clear-button {
    display: none;
  }
}
</style>
