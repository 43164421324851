export default (() => {
  let years = [];
  let startFrom = 1948;
  let endAt = new Date().getFullYear() + 1;

  for (startFrom; startFrom <= endAt; startFrom++) {
    years = [startFrom, ...years];
  }

  return years;
})();
