<template>
  <div class="vehicle-form__details">
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.details.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.details.description") }}
        </RdxInfo>
      </div>

      <div class="col-xl-8 p-4">
        <div class="row">
          <div class="col">
            <VRdxSelect
              v-model="vehicle.brand"
              :options="brandOptionsList"
              :pending="pending.load || pending.save || !brandOptionsList"
              vm-label="label"
              vm-track-by="label"
              :label="$t('stock.shared-form.details.brand-input-label')"
              :placeholder="
                $t('stock.shared-form.details.brand-input-placeholder')
              "
              :name="$t('stock.shared-form.details.brand-input-label')"
              rules="required"
              data-cy="brand"
            />
          </div>
          <div class="col">
            <VRdxSelect
              v-model="vehicle.nameplate"
              :options="currentNameplateOptions"
              :pending="
                !vehicle.brand ||
                  pending.load ||
                  pending.save ||
                  !currentNameplateOptions
              "
              vm-label="label"
              vm-track-by="label"
              :label="$t('stock.shared-form.details.model-input-label')"
              :placeholder="
                $t('stock.shared-form.details.model-input-placeholder')
              "
              :name="$t('stock.shared-form.details.model-input-label')"
              rules="required"
              data-cy="nameplate"
            />
          </div>
        </div>

        <VRdxInput
          v-model="vehicle.version"
          :pending="pending.load || pending.save"
          :label="$t('stock.shared-form.details.version-input-label')"
          :name="$t('stock.shared-form.details.version-input-label')"
          rules="required"
          data-cy="version"
        />

        <VRdxInput
          v-model="vehicle.model_no"
          label="Model no ex: HL560"
          name="Model no"
          rules="required"
          data-cy="model_no"
        />


        <VRdxTextarea
          v-model="vehicle.drivetrain"
          :pending="pending.load || pending.save"
          :label="'Drivetrain ex: 1.5lt 160BG Benzinli'"
          :name="$t('stock.shared-form.details.drivetrain-input-label')"
          rules="required"
          data-cy="drivetrain"
        >
          <template #label>
            <span>
              {{ $t("stock.shared-form.details.drivetrain-input-label") }}
            </span>
            &nbsp;
            <RdxIcon
              v-tooltip="
                $t('stock.shared-form.details.drivetrain-input-tooltip')
              "
              class="ri-question-line rdx-icon--16 rdx-icon--bluish"
            />
          </template>
        </VRdxTextarea>

        <RdxTextarea
          v-model="vehicle.short_description"
          :pending="pending.load || pending.save"
          :label="
            $t('stock.shared-form.details.additional-description-input-label')
          "
          data-cy="short_description"
        />


        <RdxCheckbox
          ref="designer_choice"
          v-model="vehicle.designer_choice"
          :pending="pending.load || pending.save"
          label="Designer Choice"
          data-cy="designer_choice"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RdxIcon,
  RdxInfo,
  RdxInput,
  RdxSelect,
  RdxCheckbox,
  RdxTextarea
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import { withValidation } from "vee-validate";
const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));
const VRdxTextarea = withValidation(RdxTextarea, ({ errors }) => ({ errors }));
const VRdxSelect = withValidation(RdxSelect, ({ errors }) => ({ errors }));

export default {
  name: "VehicleFormDetails",

  components: {
    RdxIcon,
    RdxInfo,
    RdxCheckbox,
    RdxTextarea,
    CiHeading,

    VRdxInput,
    VRdxTextarea,
    VRdxSelect
  },

  props: {
    pending: {
      required: true,
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    brandOptionsList: {
      required: true,
      type: Array,
      default: () => []
    },

    nameplateOptionsList: {
      required: true,
      type: Array,
      default: () => []
    },

    initialBrand: {
      type: Object,
      default: () => {}
    },
    initialNameplate: {
      type: Object,
      default: () => {}
    },
    initialDesignerChoice: {
      type: Boolean,
      default: null
    },
    initialVersion: {
      type: String,
      default: ""
    },
    initialModelNo: {
      type: String,
      default: ""
    },
    initialDrivetrain: {
      type: String,
      default: ""
    },
    initialDescription: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      vehicle: {
        brand: null,
        nameplate: null,
        designer_choice: null,
        version: null,
        drivetrain: null,
        model_no: null,
        short_description: null
      }
    };
  },

  computed: {
    currentNameplateOptions() {
      return this.nameplateOptionsList.filter(nameplate => {
        return parseInt(nameplate.brandId) === parseInt(this.vehicle.brand?.id);
      });
    }
  },

  watch: {
    initialBrand(value) {
      this.vehicle.brand = value;
    },
    initialDesignerChoice(value) {
      this.vehicle.designer_choice = value;
    },
    initialNameplate(value) {
      this.vehicle.nameplate = value;
    },
    initialVersion(value) {
      this.vehicle.version = value;
    },
    initialModelNo(value) {
      this.vehicle.model_no = value;
    },
    initialDrivetrain(value) {
      this.vehicle.drivetrain = value;
    },
    initialDescription(value) {
      this.vehicle.short_description = value;
    },
    "vehicle.brand"(brand, oldBrand) {
      this.$emit("vehicle:brand", brand);
      this.resetVehicleNamplate(oldBrand);
    },
    "vehicle.nameplate"(nameplate) {
      this.$emit("vehicle:nameplate", nameplate);
    },
    "vehicle.version"(version) {
      this.$emit("vehicle:version", version);
    },
    "vehicle.drivetrain"(drivetrain) {
      this.$emit("vehicle:drivetrain", drivetrain);
    },
    "vehicle.model_no"(model_no) {
      this.$emit("vehicle:model_no", model_no);
    },
    "vehicle.designer_choice"(designer_choice) {
      this.$emit("vehicle:designer_choice", designer_choice);
    },
    "vehicle.short_description"(description) {
      this.$emit("vehicle:short_description", description);
    }
  },

  created() {
    this.vehicle.brand = this.initialBrand;
    this.vehicle.nameplate = this.initialNameplate;
    this.vehicle.version = this.initialVersion;
    this.vehicle.model_no = this.initialModelNo;
    this.vehicle.drivetrain = this.initialDrivetrain;
    this.vehicle.short_description = this.initialDescription;
  },

  methods: {
    resetVehicleNamplate(oldBrand) {
      if (oldBrand !== null) this.vehicle.nameplate = null;
    }
  }
};
</script>
